:root {
// global
--bg-img-login: url('../img/background.svg');
--color-theme-1:#0078B7;
--color-theme-2:#F0A14C;
--color-theme-3:#00B4D7;
--color-theme-4:#C4C4C4;
--color-theme-5: linear-gradient(122.05deg, rgba(241, 241, 241, 0.79) 19.84%, rgba(180, 178, 178, 0.26) 83.59%)!important;
    // User
    --bg-img-kota: url('../img/background.svg');
    --bg-img-konten: url('../img/background.svg');

    // Display
    --bg-display:#ffffff;
    --bg-antrian-display:#DEDEDE;
    --bg-antrian-current:#0078B7;
    --color-time-display:#00529C;
    --color-font-card1:#ffffff;
    --color-font-card2:#ffffff;
    --color-font-card3:#0078B7;
    --bg-running-text:#0078B7;
    --color-running-text:#FFFFFF;

    // Dashboard
    --bg-img-sidebar:url('../img/bg-side-bar-dashboard.png');

    //Login
    --ReactInputVerificationCode-itemWidth: 1em;
    --ReactInputVerificationCode-itemHeight: 1em;
    --ReactInputVerificationCode-itemSpacing: 0.5rem;

}