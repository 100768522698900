// Add bootstrap style as default css framework
@import "node_modules/bootstrap/scss/bootstrap";

// Google fonts
@import url(https://fonts.googleapis.com/css?family=Poppins:400italic,600italic,400,600);

// Add material icon as default font icon
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);

// Admin panel styles
@import "../src/assets/scss/general.scss";
@import "../src/assets/scss/layout.scss";
@import "../src/assets/scss/global.scss";
